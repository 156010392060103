import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCities() {
    return this.http.get(`${this.apiUrl}/Cities/Get`);
  }

  getByRegionId(id: number) {
    return this.http.get(`${this.apiUrl}/Cities/GetByRegionId?id=${id}`);
  }
}
