import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FleetCarsRequest } from '../_models/fm/fleet-cars-request';
import { PageData } from '../_models/queryable/page-data';
import { WorkshopRelocationsRequest } from '../_models/workshop/workshop-relocations-request';

@Injectable({
  providedIn: 'root',
})
export class FleetService {
  apiUrl = environment.apiUrl;
  public loggedIn = false;

  constructor(private http: HttpClient) {}

  getFleetCars(request: FleetCarsRequest) {
    return this.http.post(`${this.apiUrl}/Fleet/GetFleetCars`, request);
  }

  getWorkshopsForAcceptance() {
    return this.http.get(`${this.apiUrl}/Fleet/GetWorkshopsForAcceptance`);
  }

  GetWorkshopRelocationsData(request: WorkshopRelocationsRequest) {
    return this.http.post(`${this.apiUrl}/Fleet/GetWorkshopRelocationsData`, request);
  }
}
