import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {ButtonModule} from '@progress/kendo-angular-buttons';
import {LoaderModule} from '@progress/kendo-angular-indicators';
import {GridModule} from '@progress/kendo-angular-grid';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TextAreaModule, TextBoxModule} from '@progress/kendo-angular-inputs';
import { NavbarNotificationsComponent } from './navbar/navbar-notifications/navbar-notifications.component';
import {TooltipsModule} from '@progress/kendo-angular-tooltip';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DialogModule,
    ButtonModule,
    LoaderModule,
    GridModule,
    DropDownsModule,
    FormsModule,
    TextBoxModule,
    TextAreaModule,
    ReactiveFormsModule,
    TooltipsModule
  ],
  declarations: [FooterComponent, NavbarComponent, SidebarComponent, NavbarNotificationsComponent],
  exports: [FooterComponent, NavbarComponent, SidebarComponent]
})
export class ComponentsModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
