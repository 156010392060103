<div class="container-fluid fill-height k-pb-2">

  <div class="wrapper">
    <!--    <div class="sidebar" data-color="indigoBlue">-->
    <!--      <app-sidebar></app-sidebar>-->
    <!--    </div>-->
    <div class="row">
      <div class="col-12">
        <div *ngIf="!active" class="col-12 bg-danger text-white text-center">
          {{'system.deactivated' | translate}}
        </div>
      </div>

      <app-navbar></app-navbar>
      <ng-template #template let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
      </ng-template>

      <div
        kendoTooltip
        showOn="none"
        [tooltipTemplate]="template"
        filter=".k-grid td"
        (mouseover)="showTooltip($event)"
        [offset]="0"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <!--  <app-nav></app-nav>-->
  <!--  <div class="row content" style="padding-top:{{paddingTop}}px">-->
  <!--    <div class="col-lg-10 col-sm-12 offset-sm-0 offset-lg-1 main-content">-->
  <!--      <router-outlet></router-outlet>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
<app-footer></app-footer>



