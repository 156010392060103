<ng-template #template let-anchor>
  <ng-container *ngIf="anchor.nativeElement.getAttribute('title1') && anchor.nativeElement.getAttribute('title1count')">
    <span>{{ anchor.nativeElement.getAttribute("title1") }}: {{ anchor.nativeElement.getAttribute("title1count") }}<br/></span></ng-container>
  <ng-container *ngIf="anchor.nativeElement.getAttribute('title2') && anchor.nativeElement.getAttribute('title2count')">
    <span>{{ anchor.nativeElement.getAttribute("title2") }}: {{ anchor.nativeElement.getAttribute("title2count") }}<br/></span></ng-container>
  <ng-container *ngIf="anchor.nativeElement.getAttribute('titlesimple')"><span>{{ anchor.nativeElement.getAttribute("titlesimple") }}<br/></span></ng-container>
</ng-template>

<span kendoTooltip filter="a" position="bottom" [tooltipTemplate]="template">
  <a *ngIf="isFmRole && !notificationOn" routerLink="/fleetManager/request" class="nav-icon notificationStatusIcon" type="button"
     [attr.titlesimple]="'fleetManager.Request.none' | translate"><img width="24" height="24" src="./../../../assets/svg/icons/bell_lt.svg"
                                                                       alt="no requests"/></a>

  <a *ngIf="isFmRole && notificationOn && this.workshopsToAcceptCount" [attr.title1]="'fleetManager.Request.relocations' | translate"
     [attr.title1count]="this.workshopsToAcceptCount" routerLink="/fleetManager/request" class="nav-icon notificationStatusIcon" type="button"><img width="24"
                                                                                                                                                    height="24"
                                                                                                                                                    src="./../../../assets/svg/icons/bell_lt_red.svg"
                                                                                                                                                    alt="requests"/></a>
  <a *ngIf="isFmRole && notificationOn && this.requestsCount" [attr.title1]="'fleetManager.Request.driversChange' | translate"
     [attr.title1count]="this.requestsCount" routerLink="/fleetManager/request" class="nav-icon notificationStatusIcon" type="button"><img width="24"
                                                                                                                                           height="24"
                                                                                                                                           src="./../../../assets/svg/icons/bell_lt_red.svg"
                                                                                                                                           alt="requests"/></a>
  <a *ngIf="isFmRole && notificationOn && this.workshopsToAcceptCount && this.requestsCount" [attr.title1]="'fleetManager.Request.relocations' | translate"
     [attr.title1count]="this.workshopsToAcceptCount" [attr.title2]="'fleetManager.Request.driversChange' | translate" [attr.title2count]="this.requestsCount"
     routerLink="/fleetManager/request" class="nav-icon notificationStatusIcon" type="button"><img width="24" height="24"
                                                                                                   src="./../../../assets/svg/icons/bell_lt_red.svg"
                                                                                                   alt="requests"/></a>

</span>
