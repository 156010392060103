import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getRegions() {
    return this.http.get(`${this.apiUrl}/Regions/Get`);
  }
}
