<!--<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary fixed-top">-->
<!--  <div class="container-fluid">-->
<!--    <div class="navbar-wrapper">-->
<!--      <div class="navbar-toggle">-->
<!--        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">-->
<!--          <span class="navbar-toggler-bar bar1"></span>-->
<!--          <span class="navbar-toggler-bar bar2"></span>-->
<!--          <span class="navbar-toggler-bar bar3"></span>-->
<!--        </button>-->
<!--      </div>-->
<!--      <button type="button" class="btn-link" *ngFor="let language of languages" (click)="setLanguage(language.languageId.toLowerCase())">-->
<!--        <img src="./../../../assets/img/flags/{{language.languageId.toLowerCase()}}.png" /> </button>-->
<!--    </div>-->
<!--    <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">-->
<!--      <span class="navbar-toggler-bar navbar-kebab"></span>-->
<!--      <span class="navbar-toggler-bar navbar-kebab">a</span>-->
<!--      <span class="navbar-toggler-bar navbar-kebab">b</span>-->
<!--    </button>-->
<!--    <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed" *ngIf="this.user?.id">-->

<!--      <div class="input-group no-border">-->
<!--        <div class="input-group-append">-->
<!--          <div class="input-group-text">-->
<!--            {{ 'driver.logged' | translate:{'name': this.user?.fullName} }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


<!--      <ul class="navbar-nav">-->
<!--        <li class="nav-item" ngbDropdown>-->
<!--          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>-->
<!--            <i class="k-icon k-i-user"></i>-->
<!--            <p>-->
<!--              <span class="d-lg-none d-md-block">{{ 'common.account'| translate }}</span>-->
<!--            </p>-->
<!--          </a>-->
<!--          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">-->

<!--            <button type="button" class="btn-link dropdown-item" (click)="editProfile()"><span class="k-icon k-i-gear"></span> {{ 'common.edit'| translate }}</button>-->

<!--            <button type="button" class="btn-link dropdown-item" (click)="logOut()"><span class="k-icon k-i-logout"></span> {{ 'common.logout'| translate }}</button>-->
<!--          </div>-->
<!--        </li>-->
<!--        &lt;!&ndash; <li class="nav-item">-->
<!--          <a class="nav-link" href="#">-->
<!--            <i class="k-icon k-i-user"></i>-->
<!--            <p>-->
<!--              <span class="d-lg-none d-md-block">Account</span>-->
<!--            </p>-->
<!--          </a>-->
<!--        </li> &ndash;&gt;-->
<!--      </ul>-->
<!--    </div>-->
<!--  </div>-->
<!--</nav>-->

<nav id="nav">
  <div class="row nav pt-3">
    <div class="col-2 d-flex justify-content-center">
      <span class="px-2"></span>
      <a [routerLink]="['/dashboard']">
        <img class="logo" height="46" src="../assets/svg/alphabet_logo.svg" width="190"/>
      </a>
    </div>
    <div class="col-8">
            <ul *ngIf="user" class="nav mainMenu">
              <li *ngFor="let item of navGroups" class="nav-item d-flex justify-content-center px-4">
                <a *ngIf="item.route !== null && item.condition === false" routerLink="{{item.route}}" class="nav-link" (click)="selectMenu(item.id)" routerLinkActive="active" [ngClass]="selectedNavGroup !== null && selectedNavGroup?.id === item.id ? 'active' : ''">{{item.name | translate}}</a>
                <a *ngIf="item.route !== null && item.condition === true" routerLink="{{item.route}}" (click)="selectMenu(item.id)" routerLinkActive="active" [ngClass]="{'active': selectedNavGroup !== null && selectedNavGroup?.id === item.id, 'nav-link': item.conditionVariable === undefined || item.conditionVariable === false, 'nav-link-coral': item.conditionVariable !== undefined && item.conditionVariable === true }">{{item.name | translate}}</a>
                <a *ngIf="item.route === null" class="nav-link" routerLinkActive="active" (click)="selectMenu(item.id)" [ngClass]="selectedNavGroup !== null && selectedNavGroup?.id === item.id ? 'active' : ''">{{item.name | translate}}</a>
              </li>
            </ul>
    </div>
    <div class="col-2">
        <span *ngIf="user" class="nav-icon nav-bar-user d-flex justify-content-center" title="{{user.username}}">{{ user.username }}</span
        ><span *ngIf="user && isFmRole !== undefined && isFmRole !== null && isFmRole === true"><app-navbar-notifications *ngIf="user && isFmRole !== undefined && isFmRole !== null && isFmRole === true" [isFmRole]="isFmRole" (requestsEmitter)="awaitingRequests($event)"></app-navbar-notifications></span
        ><span *ngFor="let language of languages"><a type="button" class="nav-icon"  (click)="setLanguage(language.languageId.toLowerCase())">
                 <img alt="{{language.languageId.toLowerCase()}}" src="./../../../assets/img/flags/{{language.languageId.toLowerCase()}}.svg" width="24" height="24"/></a></span
        ><span *ngIf="user" ><a (click)="editProfile()" class="nav-icon" type="button"><img width="24" height="24" src="./../../../assets/svg/user.svg" alt="edit profile"/></a></span><span *ngIf="user" ><a
      (click)="logOut()" class="nav-icon" type="button"><img width="24" height="24" src="./../../../assets/svg/wyloguj_icon.svg" alt="logout"/></a></span
      ><span>&nbsp;</span>
      </div>
    </div>
    <div *ngIf="this.selectedNavGroup !== null" class="row nav subMenu p-3" id="subNav">
      <div  class="col-10 offset-1">
        <ul *ngIf="user" class="nav">
          <li  *ngFor="let item of selectedNavGroup?.elements" class="nav-item d-flex justify-content-center px-3">
            <!--          <a routerLink="{{item.route}}" class="sub-nav-link" routerLinkActive="active">{{item.name | translate}}</a>-->
            <a *ngIf="item.spaRoute" routerLink="{{item.route}}" class="sub-nav-link" routerLinkActive="active">{{item.name | translate}}</a>
            <a *ngIf="!item.spaRoute" href="{{item.route}}" class="sub-nav-link" routerLinkActive="active" [ngClass]="item.selected ? 'active' : ''">{{item.name | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
</nav>

