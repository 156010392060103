import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ConfigurationDto} from '../_models/dtos/configuration-dto';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  observableAppActive: BehaviorSubject<boolean>;

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  eventChange(status) {
    this.observableAppActive.next(status);
  }

  getSystemActive() {
    return this.http.get(`${this.apiUrl}/Configuration/SystemActive`);
  }

  getAll() {
    return this.http.get(`${this.apiUrl}/Configuration/GetAll`);
  }

  getKey(key: string) {
    return this.http.get(`${this.apiUrl}/Configuration/GetKey?key=${key}`);
  }

  updateKey(request: ConfigurationDto) {
    return this.http.post(`${this.apiUrl}/Configuration/UpdateKey`, request);
  }

  getPasswordRegex() {
    return this.http.get(`${this.apiUrl}/Configuration/GetPasswordRegex`);
  }

  getPasswordDescription() {
    return this.http.get(`${this.apiUrl}/Configuration/GetPasswordDescription`);
  }

  getUserReport() {
    return this.http.get(`${this.apiUrl}/Configuration/GetUserReport`);
  }

  getReportDeposits() {
    return this.http.get(`${this.apiUrl}/Configuration/GetNonLoadedDepositReport`);
  }

  getReportDrivers() {
    return this.http.get(`${this.apiUrl}/Configuration/GetReportDrivers`);
  }

  getReportDriversForFm() {
    return this.http.get(`${this.apiUrl}/Configuration/GetReportDriversForFm`);
  }

  getReportOrders() {
    return this.http.get(`${this.apiUrl}/Configuration/GetReportAllOrdersAndRelocations`);
  }
}
