import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {environment} from '../../../src/environments/environment';
import {AuthenticationService} from '../_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  //   if (localStorage.getItem(environment.storageTokenName) != null) {
  //     return true;
  //   }

  //   // here should goes redirect
  //   this.router.navigate(['/dashboard']);
  //   return false;
  // }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser !== null && currentUser !== undefined) {
      if (route.data.roles) {
        let validation = false;
        route.data.roles.forEach(element => {
          if (currentUser.roles !== null && currentUser.roles !== undefined) {
            const index = currentUser.roles.indexOf(element);
            if (index !== -1) {
              validation = true;
            }
          }
        });
        if (!validation) {
          this.router.navigate(['']);
          return false;
        }
      }
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url

    let url = state.url;
    if (url === '/drivePanel') {
      url = '/dashboard';
    }
    this.router.navigate(['/login'], {queryParams: {returnUrl: url}});
    return false;
  }
}
