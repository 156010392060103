import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelocationsService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  accept(id: number) {
    return this.http.post(`${this.apiUrl}/Relocation/Accept`, id);
  }

  deny(id: number) {
    return this.http.post(`${this.apiUrl}/Relocation/Deny`, id);
  }

  cancel(id: number) {
    return this.http.post(`${this.apiUrl}/Relocation/Cancel`, id);
  }
}
