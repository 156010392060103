import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {CompanyDto} from '../_models/dtos/company-dto';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.apiUrl}/Company/GetAll`);
  }

  get(code: number) {
    return this.http.get(`${this.apiUrl}/Company/Get?code=${code}`);
  }

  updateCicEditable(request: CompanyDto) {
    return this.http.post(`${this.apiUrl}/Company/UpdateCicEditable`, request);
  }

  getAllForCic() {
    return this.http.get(`${this.apiUrl}/Company/GetAllForCic
`);
  }
}
