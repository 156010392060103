import { Injectable } from '@angular/core';
import { environment } from './../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { WorkshopRelocationCountRequest } from '../_models/workshop/workshop-relocation-count-request';
import { UpdateWorkshopDataRequest } from '../_models/dtos/update-workshop-data-request';
import { DisabledCompanyWorkshopDto } from '../_models/dtos/disabled-company-workshop-dto';
import { GetWorkshopByRegionCityRequest } from '../_models/workshop/get-workshop-by-region-city-request';
import { FleetCarsRequest } from '../_models/fm/fleet-cars-request';

@Injectable({
  providedIn: 'root',
})
export class WorkshopService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getWorkshops() {
    return this.http.get(`${this.apiUrl}/Workshop/GetWorkshops`);
  }

  getPagedWorkshops(request: FleetCarsRequest) {
    return this.http.post(`${this.apiUrl}/Workshop/GetPagedWorkshops`, request);
  }

  getCarWorkshop(carId: number) {
    return this.http.get(`${this.apiUrl}/Workshop/GetCarWorkshop?carId=${carId}`);
  }

  getCarLastExportedWorkshop(carId: number) {
    return this.http.get(`${this.apiUrl}/Workshop/GetCarLastExportedWorkshop?carId=${carId}`);
  }

  getWorkshop(id: number) {
    return this.http.get(`${this.apiUrl}/Workshop/GetWorkshop?workshopId=${id}`);
  }

  getAllCompanyDisabledWorkshop(id: number) {
    return this.http.get(`${this.apiUrl}/Workshop/getAllCompanyDisabledWorkshop?workshopId=${id}`);
  }

  removeWorkshop(workshopId: number) {
    return this.http.post(`${this.apiUrl}/Workshop/RemoveWorkshop`, workshopId);
  }

  getWorkshopRelocationCount(carId: number) {
    return this.http.get(`${this.apiUrl}/Workshop/GetWorkshopRelocationCount?carId=${carId}`);
  }

  getWorkshopRelocations(carId: number) {
    return this.http.get(`${this.apiUrl}/Workshop/GetWorkshopRelocations?carId=${carId}`);
  }

  updateWorkshopDepositCounts(request: UpdateWorkshopDataRequest) {
    return this.http.post(`${this.apiUrl}/Workshop/UpdateWorkshopDepositCounts`, request);
  }

  disableWorkshopsForCustomer(request: DisabledCompanyWorkshopDto[]) {
    return this.http.post(`${this.apiUrl}/Workshop/DisableWorkshopsForCustomer`, request);
  }

  getWorkshopHistory(carId: number) {
    return this.http.get(`${this.apiUrl}/Workshop/GetWorkshopHistory?carId=${carId}`);
  }

  getWorkshopByRegionCity(request: GetWorkshopByRegionCityRequest) {
    return this.http.post(`${this.apiUrl}/Workshop/GetWorkshopByRegionCity`, request);
  }


}
