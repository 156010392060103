import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.apiUrl}/Messages/GetAll`, { responseType: 'arraybuffer' }).subscribe(data => {
      const contentType = 'text/plain';
      const file = new Blob([data], { type: contentType });
      // var file = new Blob([data]);
      const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
      const anchor = document.createElement('a');
      anchor.download = 'Messages.txt';
      anchor.href = fileURL;
      anchor.click();
    });
  }
}
