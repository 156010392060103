import {filter} from 'rxjs/operators';
import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy, PopStateEvent} from '@angular/common';

import {NavbarComponent} from '../../components/navbar/navbar.component';
import {Router, NavigationEnd, NavigationStart} from '@angular/router';
import {Subscription, Observable} from 'rxjs';
import PerfectScrollbar from 'perfect-scrollbar';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../_services/authentication.service';
import {ConfigurationService} from '../../_services/configuration.service';
import {PopoverAnchorDirective, TooltipDirective} from '@progress/kendo-angular-tooltip';
import {PopoverDirectivesBase} from '@progress/kendo-angular-tooltip/popover/directives-base';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  active = true;

  constructor(
    public location: Location,
    private router: Router,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService
  ) {
    translate.setDefaultLang('pl');
    // this.authenticationService.GetUserClaims();
  }

  ngOnInit() {
    const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

    if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {
      // if we are on windows OS we activate the perfectScrollbar function

      document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off');
    }
    const elemMainPanel = <HTMLElement>document.querySelector('.main-content');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this._router = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // elemMainPanel.scrollTop = 0;
      // elemSidebar.scrollTop = 0;
    });

    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      if (elemMainPanel) {
        let ps = new PerfectScrollbar(elemMainPanel);
        
        if (elemSidebar) {
          ps = new PerfectScrollbar(elemSidebar);
        }
      }
     
    }

    this.configurationService.getSystemActive().subscribe(
      (result: boolean) => {
        this.active = result;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngAfterViewInit() {
    this.runOnRouteChange();
  }

  isMaps(path) {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1);
    if (path == titlee) {
      return false;
    } else {
      return true;
    }
  }

  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector('.main-content');
      if (elemMainPanel) {
        const ps = new PerfectScrollbar(elemMainPanel);
        ps.update();
      }
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // console.log(e);
    // console.log([element.offsetWidth, element.scrollWidth]);
    // console.log([element.offsetHeight, element.scrollHeight]);
    // console.log(element.nodeName);
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') && (element.offsetWidth < element.scrollWidth)
      || (element.className === 'gridTextCell' && element.offsetHeight < element.scrollHeight)
      || (element.className === 'k-cell-inner' && element.offsetHeight < element.scrollHeight)
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

}
