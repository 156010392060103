import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {DepositStatusEnum} from '../_models/enums/deposit-status-enum.enum';

@Injectable({
  providedIn: 'root'
})
export class DepositsService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getCarDeposit(carId: number) {
    return this.http.get(`${this.apiUrl}/Deposit/GetCarDeposit?carId=${carId}`);
  }

  updateStatus(carId: number) {
    return this.http.get(`${this.apiUrl}/Deposit/UpdateStatus?carId=${carId}`);
  }

  setStatus(carId: number, status: string) {
    const request = {
      carId: carId,
      status: status
    };
    return this.http.post(`${this.apiUrl}/Deposit/SetStatus`, request);
  }

  addRecalculateStatusService() {
    return this.http.get(`${this.apiUrl}/Deposit/AddRecalculateStatusService`);
  }
}
