import { LanguageResponseDto } from '../dtos/language-response-dto';

export class UserDto {
  id: number;
  username: string;
  token: string;
  firstName: string;
  lastName: string;
  email: string;
  createDate: Date;
  active: boolean;
  verifiedDate: Date;
  roles: string[];
  // locations: LocationDto[];
  setPasswordDate: Date;
  lastSuccessfulLoginDate: Date;
  language: LanguageResponseDto;
  errorMessage: string;
  error: boolean;

  fullName: string;

  isFm: boolean;

  construction(data: any) {
    Object.assign(this, data);
  }
}
