import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GetAllCarsRequest } from '../_models/cars/get-all-cars-request';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getAll(request: GetAllCarsRequest) {
    return this.http.post(`${this.apiUrl}/Network/GetAll`, request);
  }

  getNetworkList() {
    return this.http.get(`${this.apiUrl}/Network/GetNetworkList`);
  }
}
