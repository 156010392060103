import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DriverDto } from '../_models/dtos/driver-dto';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  apiUrl = environment.apiUrl;
  public loggedIn = false;

  constructor(private http: HttpClient) {}

  getMyData() {
    return this.http.get(`${this.apiUrl}/Driver/GetMyData`);
  }

  updateMyData(data: DriverDto) {
    return this.http.post(`${this.apiUrl}/Driver/UpdateMyData`, data);
  }

  getDriverById(driverId: number) {
    return this.http.get(`${this.apiUrl}/Driver/GetDriverById?driverId=${driverId}`);
  }

  checkEmailConflict(email: string) {
    return this.http.get(`${this.apiUrl}/Driver/CheckEmailConflict?email=${email}`);
  }

  getMyCompanyCount() {
    return this.http.get(`${this.apiUrl}/Driver/GetMyCompanyCount`);
  }

  getMyCompanyDrivers() {
    return this.http.get(`${this.apiUrl}/Driver/GetMyCompanyDrivers`);
  }

  getMyCompanyDriversPost(filter: string) {
    return this.http.post(`${this.apiUrl}/Driver/GetMyCompanyDrivers`, {filter: filter});
  }

  getDriverByCarId(carId: number) {
    return this.http.get(`${this.apiUrl}/Driver/GetDriverByCarId?carId=${carId}`);
  }

  getAllDrivers() {
    return this.http.get(`${this.apiUrl}/Driver/GetAllDrivers`);
  }

  getAllDriversPost(filter: string) {
    return this.http.post(`${this.apiUrl}/Driver/GetAllDrivers`, {filter: filter});
  }

  getDriverHistory(driverId: number) {
    return this.http.get(`${this.apiUrl}/Driver/GetDriverHistory?driverId=${driverId}`);
  }

  getDriverByEmail(email: string) {
    return this.http.get(`${this.apiUrl}/Driver/GetDriverByEmail?email=${email}`);
  }

  updateDriver(driver: DriverDto) {
    return this.http.post(`${this.apiUrl}/Driver/UpdateDriver`, driver);
  }
}
