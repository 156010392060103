import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../src/environments/environment';
import { UserCredentials } from '../_models/user-credentials';
import { map, catchError } from 'rxjs/operators';
import { Role } from '../_models/Role';
import { UserClaims } from '../_models/user-claims';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from './configuration.service';
import jwt_decode from 'jwt-decode';
import { UserDto } from '../_models/account/user-dto';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserDto>;
  public currentUser: Observable<UserDto>;

  baseUrl = environment.baseUrl;

  // old version
  private user: UserClaims;
  observableUser: BehaviorSubject<UserClaims>;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private router: Router,
    private configurationService: ConfigurationService,
    private route: ActivatedRoute
  ) {
    // this.user = new UserClaims(null);
    // this.observableUser = new BehaviorSubject<UserClaims>(this.user);

    this.currentUserSubject = new BehaviorSubject<UserDto>(JSON.parse(localStorage.getItem('currentUser')));

    this.currentUser = this.currentUserSubject.asObservable();
  }

  eventChange() {
    this.configurationService.getSystemActive().subscribe(
      (result: boolean) => {
        if (result) {
          if (this.user !== null) {
            this.SetUserLanguage(this.user.language);
          }
          this.observableUser.next(this.user);
        } else {
          if (this.user !== null && this.user.roles !== null && this.user.roles !== undefined) {
            if ('Admin' in this.user.roles) {
              if (this.user !== null) {
                this.SetUserLanguage(this.user.language);
              }
              this.observableUser.next(this.user);
            } else {
              this.user = null;
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );

    // if (this.user !== null) {
    //   this.SetUserLanguage(this.user.language);
    // }
    // this.observableUser.next(this.user);
  }

  // public get currentUserValue(): UserClaims {
  //   return this.observableUser.value;
  // }

  ValidateUser(userCredentials: UserCredentials) {
    const userData = 'username=' + userCredentials.username + '&password=' + userCredentials.password + '&grant_type=password';
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post(this.baseUrl + '/token', userData, { headers: reqHeader }).pipe(map(res => res));
  }

  // LogIn(userCredentials: UserCredentials) {
  //   const userData = 'username=' + userCredentials.username + '&password=' + userCredentials.password + '&grant_type=password';
  //   const reqHeader = new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   });
  //   this.http
  //     .post(this.baseUrl + '/token', userData, { headers: reqHeader })
  //     .pipe(map(res => res))
  //     .subscribe(
  //       (data: any) => {
  //         // checking configuration if system is active
  //         localStorage.setItem(environment.storageTokenName, data.access_token);
  //         // this.GetClaims();
  //         this.eventChange();
  //       },
  //       (error: any) => {
  //         if (error.error === 'user_not_found{"error":"invalid_grant"}') {
  //           this.ErrorUser('user_not_found');
  //         } else if (error.error === 'wrong_password{"error":"invalid_grant"}') {
  //           this.ErrorUser('wrong_password');
  //         }
  //       }
  //     );
  // }

  // GetClaims() {
  //   return this.http.get(this.baseUrl + '/api/Account/GetUserClaims').subscribe(
  //     (data: any) => {
  //       const roles: Role[] = JSON.parse(data.roles);
  //       const language: string = data.language;
  //       const userClaims = new UserClaims({
  //         id: data.id,
  //         email: data.email,
  //         firstName: data.firstName,
  //         lastName: data.lastName,
  //         roles: roles,
  //         language: language.toLowerCase(),
  //         fullName: `${data.firstName} ${data.lastName}`,
  //         errorMessage: null,
  //         error: false
  //       });
  //       this.user = userClaims;
  //       this.eventChange();
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  // GetUserClaims(): UserClaims {
  //   if (localStorage.getItem(environment.storageTokenName) != null) {
  //     this.GetClaims();
  //     return this.user;
  //   } else {
  //     return null;
  //   }
  // }

  SetUserLanguage(newLanguage: string) {
    this.translateService.use(newLanguage);
  }

  Logout() {
    localStorage.removeItem(environment.storageTokenName);
    this.EmptyUser();
    this.router.navigate(['/login']);
  }

  ErrorUser(message: string) {
    const userClaims = new UserClaims({
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      roles: [],
      fullName: null,
      language: null,
      errorMessage: message,
      error: true
    });
    this.user = userClaims;
    this.eventChange();
  }

  EmptyUser() {
    const userClaims = new UserClaims({
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      roles: [],
      fullName: null,
      language: 'pl',
      errorMessage: '',
      error: false
    });
    this.user = userClaims;
    this.eventChange();
  }

  // version from stock

  public get currentUserValue(): UserDto {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/login`, {
        username,
        password
      })
      .pipe(
        map(token => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const decoded = jwt_decode(token);
          const user = new UserDto();
          user.id = decoded['Id'];
          user.username = decoded['unique_name'];
          user.firstName = decoded['FirstName'];
          user.lastName = decoded['LastName'];
          user.token = token;
          user.fullName = `${user.firstName} ${user.lastName}`;

          user.roles = decoded['role'];

          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }
}
