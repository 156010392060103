import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OrderTyresRequest } from '../_models/dtos/order-tyres-request';
import { CancelOrderRequest } from '../_models/tyreOrder/cancel-order-request';
@Injectable({
  providedIn: 'root',
})
export class TyreOrdersService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  addOrder(request: OrderTyresRequest) {
    return this.http.post(`${this.apiUrl}/TyreOrder/AddOrder`, request);
  }

  getAll() {
    return this.http.get(`${this.apiUrl}/TyreOrder/GetAll`);
  }

  getOrder(orderId: number) {
    return this.http.get(`${this.apiUrl}/TyreOrder/GetOrder?orderId=${orderId}`);
  }

  cancelOrder(request: CancelOrderRequest) {
    return this.http.post(`${this.apiUrl}/TyreOrder/CancelOrder`, request);
  }

  orderAll() {
    return this.http.get(`${this.apiUrl}/TyreOrder/OrderAll`);
  }

  lastOrderAllResult() {
    return this.http.get(`${this.apiUrl}/TyreOrder/LastOrderAllResult`);
  }

  hasPurchaseAwaiting(carId: number) {
    return this.http.get(`${this.apiUrl}/TyreOrder/HasPurchaseAwaiting?carId=${carId}`);
  }
}
