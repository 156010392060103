import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CarLoginRequest } from '../_models/cars/car-login-request';
import { environment } from '../../environments/environment';
import { WorkshopToCarRequest } from '../_models/cars/workshop-to-car-request';
import { SetMyNewWorkshopRequest } from '../_models/cars/set-my-new-workshop-request';
import { ChangeDriverRequest } from '../_models/dtos/change-driver-request';
import { SetCarDoorToDoor } from '../_models/cars/set-car-door-to-door';
import { GetAllCarsRequest } from '../_models/cars/get-all-cars-request';
import { SelectedCarsRequest } from '../_models/cars/selected-cars-request';
import {ChangeCarCaseStatusRequest} from '../_models/dtos/change-car-case-status-request';
import {UpdateBynxDataRequest} from '../_models/cars/update-bynx-data-request';

@Injectable({
  providedIn: 'root',
})
export class CarsService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  checkCar(request: CarLoginRequest) {
    return this.http.post(`${this.apiUrl}/Cars/CheckCar`, request);
  }

  takeOverCar(request: CarLoginRequest) {
    return this.http.post(`${this.apiUrl}/Cars/TakeOverCar`, request);
  }

  getCurrentCarDriver(carId: number) {
    return this.http.post(`${this.apiUrl}/Cars/GetCurrentCarDriver`, { carId: carId });
  }

  getCurrentCarDriverData(carId: number) {
    return this.http.get(`${this.apiUrl}/Cars/GetCurrentCarDriverData?carId=${carId}`);
  }

  getMyCars() {
    return this.http.get(`${this.apiUrl}/Cars/GetMyCars`);
  }

  getMyCarsData() {
    return this.http.get(`${this.apiUrl}/Cars/GetMyCarsWithData`);
  }

  getCarById(carId: number) {
    return this.http.get(`${this.apiUrl}/Cars/GetCar?id=${carId}`);
  }

  getCarTyreStatus(carId: number) {
    return this.http.get(`${this.apiUrl}/Cars/GetCarTyreStatus?id=${carId}`);
  }

  getAllCars(request: GetAllCarsRequest) {
    return this.http.post(`${this.apiUrl}/Cars/GetAllCars`, request);
  }

  getSelectedCars(request: SelectedCarsRequest) {
    return this.http.post(`${this.apiUrl}/Cars/GetAdminSelectedCars`, request);
  }

  setWorkshopToCar(request: WorkshopToCarRequest) {
    return this.http.post(`${this.apiUrl}/Cars/SetWorkshopToCar`, request);
  }
  setMyNewWorkshop(request: SetMyNewWorkshopRequest) {
    return this.http.post(`${this.apiUrl}/Cars/SetMyNewWorkshop`, request);
  }

  changeWorkshop(request: SetMyNewWorkshopRequest) {
    return this.http.post(`${this.apiUrl}/Cars/ChangeWorkshop`, request);
  }

  forceChangeWorkshop(request: SetMyNewWorkshopRequest) {
    return this.http.post(`${this.apiUrl}/Cars/ForceChangeWorkshop`, request);
  }

  changeWorkshopNoDriver(request: SetMyNewWorkshopRequest) {
    return this.http.post(`${this.apiUrl}/Cars/ChangeWorkshopNoDriver`, request);
  }

  changeCarDriver(request: ChangeDriverRequest) {
    return this.http.post(`${this.apiUrl}/Cars/ChangeDriver`, request);
  }

  changeCarDriverAdmin(request: ChangeDriverRequest) {
    return this.http.post(`${this.apiUrl}/Cars/ChangeDriverAdmin`, request);
  }

  setCarDoorToDoor(request: SetCarDoorToDoor) {
    return this.http.post(`${this.apiUrl}/Cars/SetCarDoorToDoor`, request);
  }

  changeActiveState(carId: number, state: boolean) {
    if (state) {
      return this.http.get(`${this.apiUrl}/Cars/ActivateCar?carId=${carId}`);
    } else {
      return this.http.get(`${this.apiUrl}/Cars/DeactivateCar?carId=${carId}`);
    }
  }

  getCarHistory(carId: number) {
    return this.http.get(`${this.apiUrl}/Cars/GetCarHistory?carId=${carId}`);
  }

  /// not used anywhere - remove?
  contractExpiredCheck(carId: number) {
    return this.http.get(`${this.apiUrl}/Cars/ContractExpiredCheck?carId=${carId}`);
  }

  changeCarCaseStatus(request: ChangeCarCaseStatusRequest) {
    return this.http.post(`${this.apiUrl}/Cars/ChangeCarCaseStatus`, request);
  }

  getCarCaseStatusStatistics() {
    return this.http.get(`${this.apiUrl}/Cars/GetCarCaseStatusStatistics`);
  }

  updateBynxData(request: UpdateBynxDataRequest) {
    return this.http.post(`${this.apiUrl}/Cars/UpdateBynxData`, request);
  }
}
