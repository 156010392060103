<!--<footer>-->
<!--  <div>-->
<!--    Copyright &copy; {{test | date: 'yyyy'}},-->
<!--    <a href="https://www.alphabet.pl" target="_blank">Alphabet</a>-->
<!--    <br/>-->
<!--    <a routerLink="/polityka-ochrony-danych-osobowych">{{'sidebar.giodo' | translate}}</a> | <a routerLink="/informacje-prawne">{{'sidebar.legalNotes' | translate}}</a> | <a routerLink="/polityka-cookie">{{'sidebar.cookies' | translate}}</a> | <a routerLink="/wlasciciel">{{'sidebar.owner' | translate}}</a>-->
<!--  </div>-->
<!--</footer>-->

<footer>
  <div>
    Copyright {{test | date: 'yyyy'}} Alphabet Polska

    <a routerLink="/polityka-ochrony-danych-osobowych">{{'sidebar.giodo' | translate}}</a>
    <a routerLink="/informacje-prawne">{{'sidebar.legalNotes' | translate}}</a>
    <a routerLink="/polityka-cookie">{{'sidebar.cookies' | translate}}</a>
    <a routerLink="/wlasciciel">{{'sidebar.owner' | translate}}</a>


  </div>
</footer>
