import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FleetService} from '../../../_services/fleet.service';
import {WorkshopRelocationDto} from '../../../_models/dtos/workshop-relocation-dto';
import {CarDriverService} from '../../../_services/car-driver.service';
import {Subscription, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {GlobalService} from '../../../_services/global.service';

@Component({
  selector: 'app-navbar-notifications',
  templateUrl: './navbar-notifications.component.html',
  styleUrls: ['./navbar-notifications.component.scss']
})
export class NavbarNotificationsComponent implements OnInit {
  @Input() isFmRole = false;
  @Output() requestsEmitter = new EventEmitter<boolean>();

  workshopsToAccept: WorkshopRelocationDto[];
  workshopsToAcceptCount = 0;
  requestsCount = 0;

  notificationOn = false;


  constructor(private fleetService: FleetService, private carDriverService: CarDriverService, private global: GlobalService) {
  }

  ngOnInit(): void {
    this.global.timerSubscription = timer(0, 60000).pipe(
      map(() => {
        this.getFleetManagerChecks(); // load data contains the http request
      })
    ).subscribe();
  }

  getFleetManagerChecks() {
    if (this.isFmRole !== undefined && this.isFmRole !== null && this.isFmRole === true) {
      this.getUnacceptedRelocations();
    } else {
      this.global.timerSubscription.unsubscribe();
    }
  }

  getUnacceptedRelocations() {
    // console.log('getUnacceptedRelocations');
    this.fleetService.getWorkshopsForAcceptance().subscribe({
      next: (result: WorkshopRelocationDto[]) => {
        this.workshopsToAccept = result;
        this.workshopsToAcceptCount = this.workshopsToAccept.length;
        if (this.workshopsToAcceptCount > 0) {
          this.notificationOn = true;
          this.requestsEmitter.emit(true);
        } else {
          this.requestsEmitter.emit(false);
        }
        // this.getRequestsCount();
      },
      error: err => {
        console.log(err);
      }
    });
  }

  getRequestsCount() {
    // console.log('getRequestsCount');
    this.carDriverService.getRequestsCount().subscribe({
      next: (result: number) => {
        this.requestsCount = result;
        this.notificationOn = result > 0 || this.workshopsToAcceptCount > 0;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

}
