import {Injectable} from '@angular/core';
import {NotificationService} from '@progress/kendo-angular-notification';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  constructor(private notify: NotificationService, private translationService: TranslateService) {
  }

  private hideAfter = 10000;
  private duration = 500;
  private closable = true;
  private icon = true;

  none(message: string) {
    this.showMessage(message, 'none');
  }

  success(message: string, translate = true) {
    this.showMessage(message, 'success', translate);
  }

  info(message: string, translate = true) {
    this.showMessage(message, 'info', translate);
  }

  error(message: string, translate = true) {
    this.showMessage(message, 'error', translate);
  }

  warning(message: string, translate = true) {
    this.showMessage(message, 'warning', translate);
  }

  showMessage(message: string, type: any, translate = true) {
    let content = message;
    if (translate) {
      content = this.checkTranslation(message);
    }

    this.notify.show({
      content: content,
      cssClass: 'button-notification',
      animation: {type: 'slide', duration: this.duration},
      position: {horizontal: 'center', vertical: 'top'},
      type: {style: type, icon: this.icon},
      hideAfter: this.hideAfter,
      // closable: true,
    });
  }

  public checkTranslation(message: string) {
    const prefix = 'notify.';
    let translateKey = message;
    if (!message.startsWith(prefix)) {
      translateKey = prefix + message;
    }

    const translated = this.translationService.instant(translateKey);
    return translated;
  }
}
