import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ChangeMaxRelocationRequest } from '../_models/dtos/change-max-relocation-request';
import { UpdateCarDriverDataRequest } from '../_models/dtos/update-car-driver-data-request';
import { CommonQueryable } from '../_models/queryable/common-queryable';

@Injectable({
  providedIn: 'root',
})
export class CarDriverService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  changeMaxRelocations(request: ChangeMaxRelocationRequest) {
    return this.http.post(`${this.apiUrl}/CarDriver/ChangeMaxRelocations`, request);
  }

  updateCarDriverData(request: UpdateCarDriverDataRequest) {
    return this.http.post(`${this.apiUrl}/CarDriver/UpdateCarDriverData`, request);
  }

  getCarDriversHistory(carId: number) {
    return this.http.get(`${this.apiUrl}/CarDriver/GetCarDriversHistory?carId=${carId}`);
  }

  getRequestsCount() {
    return this.http.get(`${this.apiUrl}/CarDriver/GetRequestsCount`);
  }

  getRequests(request: CommonQueryable) {
    return this.http.post(`${this.apiUrl}/CarDriver/GetRequests`, request);
  }

  carDriverRequestAccept(id: number) {
    return this.http.get(`${this.apiUrl}/CarDriver/CarDriverRequestAccept?id=${id}`);
  }

  carDriverRequestCancel(id: number) {
    return this.http.get(`${this.apiUrl}/CarDriver/CarDriverRequestCancel?id=${id}`);
  }
}
