import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsersGetAllRequest } from '../_models/users/users-get-all-request';
import { environment } from 'src/environments/environment';
import { NewUserRequest } from '../_models/users/new-user-request';
import { UpdateUserRequest } from '../_models/users/update-user-request';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) {}

  // roles
  getAllRoles() {
    return this.http.get(`${environment.apiUrl}/roles/GetAll`);
  }

  getAllPagination(request: UsersGetAllRequest) {
    return this.http.post(`${environment.apiUrl}/users/GetAll`, request);
  }

  createUser(request: NewUserRequest) {
    return this.http.post(`${environment.apiUrl}/users/CreateUser`, request);
  }

  updateUser(request: UpdateUserRequest) {
    return this.http.post(`${environment.apiUrl}/users/UpdateUser`, request);
  }

  activate(id: number) {
    return this.http.get(`${environment.apiUrl}/users/Activate?id=${id}`);
  }

  deactivate(id: number) {
    return this.http.get(`${environment.apiUrl}/users/Disable?id=${id}`);
  }

  getUser(id: number) {
    return this.http.get(`${environment.apiUrl}/users/GetUser?id=${id}`);
  }

  remove(id: number) {
    return this.http.get(`${environment.apiUrl}/users/Remove?id=${id}`);
  }
}
