import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { OrderRelocationsRequest } from '../_models/orders/order-relocations-request';
import { OrderTyresRequest } from '../_models/orders/order-tyres-request';
import { TyreOrderCommandRequest } from '../_models/orders/tyre-order-command-request';
import { RelocationCommandRequest } from '../_models/orders/relocation-command-request';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  apiUrl = environment.apiUrl;

  adminUrl = `${this.apiUrl}/AdminOrders`;

  constructor(private http: HttpClient) {}

  getRelocations(request: OrderRelocationsRequest) {
    return this.http.post(`${this.adminUrl}/GetRelocations`, request);
  }

  getTyresOrders(request: OrderTyresRequest) {
    return this.http.post(`${this.adminUrl}/GetTyresOrders`, request);
  }

  cancelRelocation(request: RelocationCommandRequest) {
    return this.http.post(`${this.adminUrl}/CancelRelocation`, request);
  }

  completeRelocation(request: RelocationCommandRequest) {
    return this.http.post(`${this.adminUrl}/CompleteRelocation`, request);
  }

  cancelOrder(request: TyreOrderCommandRequest) {
    return this.http.post(`${this.adminUrl}/CancelTyreOrder`, request);
  }

  completeOrder(request: TyreOrderCommandRequest) {
    return this.http.post(`${this.adminUrl}/CompleteTyreOrder`, request);
  }
}
